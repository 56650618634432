import React, { useMemo, useRef, useState } from 'react'
import {
  Flex,
  Text,
  Stack,
  createStyles,
  rem,
  Space,
  Burger,
  useMantineTheme,
  Drawer,
  Divider,
} from '@mantine/core'
import { useTranslation } from 'react-i18next'
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom'
import { useDisclosure, useMediaQuery } from '@mantine/hooks'
import { IconChevronRight } from '@tabler/icons-react'
import Web3Button from '@/components/Buttons/Web3Button'
import { useAuth } from '@/plugins/auth'
import HeaderButton from '../Buttons/HeaderButton'
import whiteLogo from '../../assets/lamina1_white.png'
import blackLogo from '../../assets/lamina1_black.png'
import UserHeader from '../User/UserHeader'
import IconWrapper from '../MediaWrappers/IconWrapper'
import HeaderIconWrapper from './HeaderIconWrapper'

import airdropBlackIcon from '../../assets/icons/airdrop_black.svg'
import airdropWhiteIcon from '../../assets/icons/airdrop_white.svg'
import homeBlackIcon from '../../assets/icons/home_black.svg'
import homeWhiteIcon from '../../assets/icons/home_white.svg'
import sendBlackIcon from '../../assets/icons/send_black.svg'
import sendWhiteIcon from '../../assets/icons/send_white.svg'
import activityBlackIcon from '../../assets/icons/activity_black.svg'
import activityWhiteIcon from '../../assets/icons/activity_white.svg'
import itemsBlackIcon from '../../assets/icons/items_black.svg'
import itemsWhiteIcon from '../../assets/icons/items_white.svg'
import studioBlackIcon from '../../assets/icons/studio_black.svg'
import studioWhiteIcon from '../../assets/icons/studio_white.svg'
import Footer from '../Footer'

const useStyles = createStyles(theme => ({
  logo: {
    height: 30,
    width: 135,
  },
  logoPhone: {
    height: 32,
    width: 32,
  },
  headerFlexRow: {
    position: 'fixed',
    padding: '25px',
    height: '30px',
    zIndex: 201,
    top: '0',
    left: '0',
    right: '0',
    backgroundColor: theme.colors.background[0],
    '@media (max-width: 400px)': {
      padding: '15px',
      height: '40px',
    },
  },
  mobileHeader: {
    position: 'fixed',

    padding: '10px 25px ',
    zIndex: 201,
    bottom: '0',
    left: '0',
    right: '0',
    gap: '10px',
    backgroundColor: theme.colors.background[0],
  },
  headerButtonSet: {
    marginLeft: 45,
    justifyContent: 'flex-start',
    width: 'auto',
  },
  balanceInfo: {
    textAlign: 'left',
  },
  balanceLabel: {
    color: theme.colors.textAndIcon[0],
    fontFamily: 'monument-grotesk-regular',
    fontSize: 14,
    fontWeight: 400,
    letterSpacing: 1.68,
    textTransform: 'uppercase',
  },
  balanceAmount: {
    fontFamily: 'monument-grotesk-black',
    fontWeight: 700,
    fontSize: rem(22),
    color: theme.colors.title[0],
  },
  userInfoContainer: {
    display: 'flex',
    flexGrow: 1,
  },
  headerButton: {
    height: 42,
    width: 150,
    backgroundColor: theme.colors.background[0],
    color: theme.colors.title[0],
    border: `2px solid ${theme.colors.title[0]}`,
    borderRadius: 0,
    fontFamily: 'monument-grotesk-black',
    fontSize: 14,
    fontWeight: 700,
    fill: theme.colors.title[0],
    '&:hover': {
      backgroundColor: theme.colors.title[0],
      color: theme.colors.background[0],
      borderColor: theme.colors.title[0],
    },
  },
  headerButtonActive: {
    backgroundColor: theme.colors.title[0],
    color: theme.colors.background[0],
    fill: theme.colors.background[0],
  },
  drawer: {
    section: {
      padding: '1em',
      backgroundColor: theme.colors.background2[0],
      opacity: 0.9,
      color: theme.colors.title[0],
      '&:focus': {
        outline: 'none',
      },
    },
  },
  headerButtonSetBurger: {
    justifyContent: 'flex-start',
    width: '100%',
  },
  burger: {
    zIndex: 202,
  },
  burgerTitle: {
    color: theme.colors.title[0],
    fontFamily: 'monument-grotesk-heavy, sans-serif',
    fontSize: '20px',
    marginLeft: '15px',
  },
  burgerHeader: {
    backgroundColor: theme.colors.background2[0],
    opacity: 0.9,
    color: theme.colors.title[0],
  },
  burgerCloseButton: {
    color: theme.colors.title[0],
    margin: '0 0 15px 15px',
  },
  burgerBody: {
    marginLeft: '15px',
    marginTop: '10px',
  },
  iconBurger: {
    height: '12px',
    width: '16px',
  },
  burgerButton: {
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row',
    padding: '10px 0px',
  },
  burgerButtonText: {
    color: theme.colors.title[0],
    fontFamily: 'monument-grotesk-heavy, sans-serif',
    fontSize: '14px',
  },
  burgerIconAndText: {
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexDirection: 'row',
    gap: '10px',
  },
  divider: {
    height: '1px',
    borderTopColor: theme.colors.splitter[0],
  },
  page: {
    width: 'auto',
    margin: '25px',
  },
  logoStack: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
}))

// build burgerButton component having as a template the stack that has activity
interface BurgerButtonProps {
  toggle: () => void
  close: () => void
  pageName: string
  pagePath: string
  iconDark: string
  iconLight: string
}

const BurgerButton: React.FC<BurgerButtonProps> = ({
  toggle,
  close,
  pageName,
  pagePath,
  iconLight,
  iconDark,
}) => {
  const { classes } = useStyles()
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const theme = useMantineTheme()
  return (
    <>
      <Divider className={classes.divider} />
      <Stack
        className={classes.burgerButton}
        onClick={() => {
          navigate(pagePath)
          close()
          toggle()
        }}
      >
        <Stack className={classes.burgerIconAndText}>
          <IconWrapper classes={classes.iconBurger} iconDark={iconDark} iconLight={iconLight} />
          <Text
            style={{
              color: pathname === pagePath ? theme.colors.l1Primary[0] : theme.colors.title[0],
              fontFamily: 'monument-grotesk-heavy, sans-serif',
              fontSize: '14px',
            }}
            className={classes.burgerButtonText}
            transform="uppercase"
          >
            {pageName}
          </Text>
        </Stack>
        <IconChevronRight size={20} />
      </Stack>
    </>
  )
}

// FC component for hoveredHeaderButtons
// interface
interface HoveredHeaderButtonProps {
  pageName: string
  pagePath: string
  iconDark: string
  iconLight: string
}

const HoveredHeaderButton: React.FC<HoveredHeaderButtonProps> = ({
  pageName,
  pagePath,
  iconDark,
  iconLight,
}) => {
  const { pathname } = useLocation()
  const iconOnly = useMediaQuery(`(max-width:1400px)`)
  const [isHovered, setIsHovered] = useState<boolean>(false)
  const ref = useRef(null)

  return (
    <div ref={ref} onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
      <HeaderButton
        iconOnly={iconOnly}
        leftIcon={
          <HeaderIconWrapper
            selected={pathname === pagePath}
            hovered={isHovered}
            iconDark={iconDark}
            iconLight={iconLight}
          />
        }
        uppercase
        pagePath={pagePath}
      >
        {iconOnly ? '' : pageName}
      </HeaderButton>
    </div>
  )
}

interface HeaderProps {
  burgerMenu?: boolean
  children?: React.ReactNode
}

const Header: React.FC<HeaderProps> = ({ burgerMenu = false, children }) => {
  const theme = useMantineTheme()
  const { t } = useTranslation()
  const { classes } = useStyles()
  const { status } = useAuth()

  const isMobile = useMediaQuery(`(max-width: 870px)`)

  const [iconOpened, { toggle }] = useDisclosure()
  const [drawerOpened, { open, close }] = useDisclosure(false)

  const lamina1Logo = useMemo(
    () => (
      <IconWrapper
        key="desktop"
        classes={classes.logo}
        iconDark={whiteLogo}
        iconLight={blackLogo}
      />
    ),
    [classes.logo]
  )

  return (
    <div
      // Need to add this style to fix the footer issue
      style={{
        display: 'grid',
        gridTemplateRows: '1fr min-content',
        gridAutoColumns: '100%',
        minHeight: '100vh',
      }}
    >
      {status === 'connected' ? (
        <>
          <Flex className={classes.headerFlexRow} direction="row">
            <Stack align="flex-start">
              <Drawer.Root
                opened={drawerOpened}
                onClose={() => {
                  close()
                  toggle()
                }}
                size="auto"
                position="left"
                className={classes.drawer}
                lockScroll={false}
              >
                <Drawer.Overlay />
                <Drawer.Content>
                  <Drawer.Header className={classes.burgerHeader}>
                    <Drawer.Title className={classes.burgerTitle}>{lamina1Logo}</Drawer.Title>
                    <Drawer.CloseButton className={classes.burgerCloseButton} />
                  </Drawer.Header>
                  <Drawer.Body className={classes.burgerBody}>
                    <Flex className={classes.headerButtonSetBurger} direction="column">
                      <BurgerButton
                        toggle={toggle}
                        close={close}
                        pageName={
                          t(
                            'components.home.title',
                            'Home'
                          ) /* TODO: replace pageNames by the component's title translations */
                        }
                        pagePath="/home"
                        iconLight={homeBlackIcon}
                        iconDark={homeWhiteIcon}
                      />
                      <BurgerButton
                        toggle={toggle}
                        close={close}
                        pageName={t('components.studio.button', 'Studio')}
                        pagePath="/studio"
                        iconLight={studioBlackIcon}
                        iconDark={studioWhiteIcon}
                      />
                      <BurgerButton
                        toggle={toggle}
                        close={close}
                        pageName={t('components.items.title', 'Items')}
                        pagePath="/items"
                        iconLight={itemsBlackIcon}
                        iconDark={itemsWhiteIcon}
                      />
                      <BurgerButton
                        toggle={toggle}
                        close={close}
                        pageName={t('components.activity.title', 'Activity')}
                        pagePath="/activity"
                        iconLight={activityBlackIcon}
                        iconDark={activityWhiteIcon}
                      />
                      <BurgerButton
                        toggle={toggle}
                        close={close}
                        pageName={t('components.send.title', 'Send')}
                        pagePath="/send"
                        iconLight={sendBlackIcon}
                        iconDark={sendWhiteIcon}
                      />
                      <BurgerButton
                        toggle={toggle}
                        close={close}
                        pageName={t('components.header.airdrop', 'Airdrop')}
                        pagePath="/airdrop"
                        iconLight={airdropBlackIcon}
                        iconDark={airdropWhiteIcon}
                      />
                      <Divider className={classes.divider} />
                    </Flex>
                  </Drawer.Body>
                </Drawer.Content>
              </Drawer.Root>

              {isMobile && burgerMenu && (
                <Burger
                  className={classes.burger}
                  color={theme.colors.title[0]}
                  key="mobile"
                  opened={iconOpened}
                  onClick={() => (!iconOpened ? (open(), toggle()) : (close(), toggle()))}
                  aria-label={t('components.header.burgerLabel', 'Toggle navigation')}
                />
              )}
              {!isMobile && (
                <Text align="center">
                  {lamina1Logo}
                  {/* <img style={{ height: 20, position: 'absolute', top: '49px', left: '177.3px' }} src={betaPill} alt='Beta Pill' /> */}
                </Text>
              )}
            </Stack>
            {!isMobile && (
              <Flex className={classes.headerButtonSet} gap="10px" justify="flex-start">
                <HoveredHeaderButton
                  pageName={t('components.home.title', 'Home')}
                  pagePath="/home"
                  iconDark={homeBlackIcon}
                  iconLight={homeWhiteIcon}
                />
                <HoveredHeaderButton
                  pageName={t('components.studio.button', 'Studio')}
                  pagePath="/studio"
                  iconDark={studioBlackIcon}
                  iconLight={studioWhiteIcon}
                />
                <HoveredHeaderButton
                  pageName={t('components.items.title', 'Items')}
                  pagePath="/items"
                  iconDark={itemsBlackIcon}
                  iconLight={itemsWhiteIcon}
                />
                <HoveredHeaderButton
                  pageName={t('components.activity.title', 'Activity')}
                  pagePath="/activity"
                  iconDark={activityBlackIcon}
                  iconLight={activityWhiteIcon}
                />
                <HoveredHeaderButton
                  pageName={t('components.send.title', 'Send')}
                  pagePath="/send"
                  iconDark={sendBlackIcon}
                  iconLight={sendWhiteIcon}
                />
                <HoveredHeaderButton
                  pageName={t('components.airdrop.title', 'Airdrop')}
                  pagePath="/airdrop"
                  iconDark={airdropBlackIcon}
                  iconLight={airdropWhiteIcon}
                />
              </Flex>
            )}
            <Space />
            <Flex className={classes.userInfoContainer} justify="flex-end">
              <UserHeader />
            </Flex>
          </Flex>
          {isMobile && !burgerMenu && (
            <Flex className={classes.mobileHeader} direction="row">
              <HoveredHeaderButton
                pageName={t('components.home.title', 'Home')}
                pagePath="/home"
                iconDark={homeBlackIcon}
                iconLight={homeWhiteIcon}
              />
              <HoveredHeaderButton
                pageName={t('components.send.title', 'Send')}
                pagePath="/send"
                iconDark={sendBlackIcon}
                iconLight={sendWhiteIcon}
              />
              <HoveredHeaderButton
                pageName={t('components.activity.title', 'Activity')}
                pagePath="/activity"
                iconDark={activityBlackIcon}
                iconLight={activityWhiteIcon}
              />
              <HoveredHeaderButton
                pageName={t('components.items.title', 'Items')}
                pagePath="/items"
                iconDark={itemsBlackIcon}
                iconLight={itemsWhiteIcon}
              />
            </Flex>
          )}
          {!children ? <Outlet /> : children}
        </>
      ) : (
        <Stack className={classes.page}>
          <Stack className={classes.logoStack}>
            <Link to="https://www.lamina1.com/" target="_blank">
              <img className={classes.logo} src={whiteLogo} alt="Logo" />
            </Link>
            <Web3Button center={false} />
          </Stack>
          {!children ? <Outlet /> : children}
        </Stack>
      )}
      <Footer />
    </div>
  )
}

export default Header
